
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import DeliveryPostalCode from '@/components/venue/form/DeliveryPostalCode.vue';
import DeliveryFeesRadiusForm from '@/components/venue/form/DeliveryFeesRadiusForm.vue';
import DeliveryFeesPostalCodeForm from '@/components/venue/form/DeliveryFeesPostalCodeForm.vue';
import { DeliveryFeePostalCode } from '@/interfaces/models/DeliveryFeePostalCode';
import { FeeByRadius } from '@/interfaces/models/delivery/FeeByRadius';
import { Fee } from '@/interfaces/models/delivery/Fee';
import { FeeByPostalCode } from '@/interfaces/models/delivery/FeeByPostalCode';
import { Permission } from '@/enums/Permission';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: {
    DeliveryFeesRadiusForm,
    DeliveryFeesPostalCodeForm,
    DeliveryPostalCode,
    VFormBuilder,
  },
})
export default class PreorderForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: true }) public offsets!: boolean;
  @Prop({ type: Boolean, default: true }) public fees!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderDelivery!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderTakeAway!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderParkCollect!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderFoodspot!: boolean;
  @Prop({ type: Boolean, default: false }) public disablePreorderInside!: boolean;
  @Prop({ type: Boolean, default: false }) public disableDeliveryPostalCode!: boolean;

  public postalCodes: string[] = [];
  public deliveryFeeType: 'radius' | 'postalCode' = 'radius';
  public isMounted: boolean = false;
  public minValues: { mov: string; fee: string; deliveryRadius?: number } = {
    mov: '0.00',
    fee: '0.00',
    deliveryRadius: 20,
  };

  public $refs!: {
    preorderForm: InstanceType<typeof VFormBuilder> & { form: any };
    feePostalCodeForm: InstanceType<typeof VFormBuilder> & { fees: DeliveryFeePostalCode[] };
    feeRadiusForm: InstanceType<typeof VFormBuilder> & { fees: FeeByRadius[] };
    preorderOffsetForm: InstanceType<typeof VFormBuilder> & { form: any };
    fireTimeForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  public mounted() {
    // @ts-ignore
    if (this.initialValues && this.initialValues.deliveryByRadius) {
      this.deliveryFeeType = 'radius';
      // @ts-ignore
    } else if (this.initialValues && !this.initialValues.deliveryByRadius) {
      this.deliveryFeeType = 'postalCode';
    }

    // @ts-ignore
    if (this.initialValues && this.initialValues.movDelivery) {
      // @ts-ignore
      const mov: any = this.initialValues.movDelivery;
      this.minValues.mov = (mov && mov.$numberDecimal) || mov;
    }

    if (this.$refs.preorderForm && this.$refs.preorderForm.form.deliveryFee) {
      const fee: any = this.$refs.preorderForm.form.deliveryFee;
      this.minValues.fee = (fee && fee.$numberDecimal) || fee;
    }

    // @ts-ignore
    if (this.initialValues && this.initialValues.deliveryRadius) {
      // @ts-ignore
      this.minValues.deliveryRadius = this.initialValues.deliveryRadius;
    }

    this.isMounted = true;
  }

  get preorderItems() {
    return [
      { name: 'panicEndAt', type: InputType.DateTime, label: 'venue.form.panicEndAt' },
      {
        name: 'preorderTakeAway',
        type: InputType.Checkbox,
        label: 'venue.form.preorderTakeAway',
        default: false,
        visible: !this.disablePreorderTakeAway,
      },
      {
        name: 'preorderParkCollect',
        type: InputType.Checkbox,
        label: 'venue.form.preorderParkCollect',
        default: false,
        visible: !this.disablePreorderParkCollect,
      },
      {
        name: 'preorderFoodspot',
        type: InputType.Checkbox,
        label: 'venue.form.preorderFoodspot',
        default: false,
        visible: !this.disablePreorderFoodspot,
      },
      {
        name: 'deliveryByRadius',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryByRadius',
        default: true,
        visible: !this.disablePreorderDelivery,
        change: this.deliveryByRadiusChangeHandler,
      },
      { name: 'deliveryPostalCodes', type: InputType.Hidden, default: [] },
      {
        name: 'preorderIn',
        type: InputType.Checkbox,
        label: 'venue.form.preorderIn',
        default: false,
        visible: !this.disablePreorderInside,
      },
      {
        name: 'deliveryEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.preorderDelivery',
        default: false,
        visible: !this.disablePreorderDelivery,
      },
      {
        name: 'cateringTakeAway',
        type: InputType.Checkbox,
        label: 'venue.form.cateringTakeAway',
        default: false,
      },
      {
        name: 'cateringDelivery',
        type: InputType.Checkbox,
        label: 'venue.form.cateringDelivery',
        default: false,
      },
      {
        name: 'couponsEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.couponsEnabled',
        default: false,
        visible: this.initialValues ? this.canChangeCoupon(this.initialValues as Venue) : true,
      },
      {
        name: 'movDelivery',
        type: InputType.Price,
        label: 'venue.form.movDelivery',
        // @ts-ignore
        labelProp: { currency: this.initialValues ? this.initialValues.currency : 'EUR' },
        default: '0.00',
        visible: !this.disablePreorderDelivery,
        change: this.movChangeHandler,
      },
      {
        name: 'deliveryFee',
        type: InputType.Price,
        label: 'venue.form.deliveryFee',
        // @ts-ignore
        labelProp: { currency: this.initialValues ? this.initialValues.currency : 'EUR' },
        default: this.deliveryFeeDefault,
        visible: !this.disablePreorderDelivery,
      },
      {
        name: 'deliveryRadius',
        type: InputType.Slider,
        label: 'venue.form.deliveryRadiusMax',
        min: 1,
        max: 20,
        default: 20,
        visible: !this.disablePreorderDelivery && this.deliveryFeeType === 'radius',
        appendMsg: 'km',
        change: this.deliveryRadiusChangeHandler,
      },
    ];
  }

  get deliveryFeeDefault() {
    return (
      (this.initialValues &&
        // @ts-ignore
        this.initialValues.movAndFee &&
        // @ts-ignore
        this.initialValues.movAndFee.fee &&
        // @ts-ignore
        this.initialValues.movAndFee.fee.$numberDecimal) ||
      '0.00'
    );
  }

  get showDeliveryFeesRadiusForm() {
    return this.isMounted && !this.disablePreorderDelivery && this.deliveryFeeType === 'radius';
  }

  get showDeliveryFeesPostalCodeForm() {
    return (
      this.isMounted &&
      !this.disableDeliveryPostalCode &&
      !this.disablePreorderDelivery &&
      this.deliveryFeeType === 'postalCode'
    );
  }

  get getPreorderOffsetItems() {
    return [{ name: 'autoDeclineTime', type: InputType.Text, label: 'venue.form.autoDeclineTime', default: 15 }];
  }

  get fireTimeItems() {
    return [
      {
        name: 'takeAway',
        type: InputType.Text,
        label: 'venue.form.takeAway',
        default: null,
        rules: 'integer|min_value:0',
      },
      {
        name: 'delivery',
        type: InputType.Text,
        label: 'venue.form.delivery',
        default: null,
        rules: 'integer|min_value:0',
      },
      {
        name: 'foodspot',
        type: InputType.Text,
        label: 'venue.form.foodspot',
        default: null,
        rules: 'integer|min_value:0',
      },
    ];
  }

  get getUberDeliveryItems() {
    return [
      {
        name: 'uberCustomerId',
        type: InputType.Text,
        label: 'venue.form.uberCustomerId',
      },
      {
        name: 'uberDeliveryEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.uberDeliveryEnabled',
      },
      {
        name: 'uberBatchSize',
        type: InputType.Text,
        default: 1,
        rules: 'integer|min:1',
        label: 'venue.form.uberBatchSize',
      },
    ];
  }

  get byRadiusFees(): FeeByRadius[] | null {
    return this.$refs.feeRadiusForm ? this.$refs.feeRadiusForm.getData() : null;
  }

  get byPostalCodeFees(): FeeByPostalCode[] | null {
    return this.$refs.feePostalCodeForm ? this.$refs.feePostalCodeForm.getData() : null;
  }

  get byRadiusFeesLegacy() {
    const radiusFees = [];
    const feesByRadius: FeeByRadius[] = this.byRadiusFees || [];

    if (feesByRadius.length === 0) {
      radiusFees.push({
        from: this.$refs.preorderForm.form.movDelivery,
        fee: this.$refs.preorderForm.form.deliveryFee,
      });
      return radiusFees;
    }

    const maxRadiusFee: FeeByRadius = feesByRadius.reduce(
      (prevFee: FeeByRadius, curFee: FeeByRadius): FeeByRadius => (curFee.radius > prevFee.radius ? curFee : prevFee),
    );

    const fees = maxRadiusFee.fees && maxRadiusFee.fees.length > 0 ? maxRadiusFee.fees : null;

    if (!fees) {
      radiusFees.push({
        from: maxRadiusFee.mov,
        fee: this.$refs.preorderForm.form.deliveryFee,
      });
      return radiusFees;
    }

    for (const fee of fees) {
      radiusFees.push({
        from: fee.from,
        fee: fee.fee,
      });
    }

    return radiusFees;
  }

  get byPostalCodeFeesLegacy() {
    const postalCodeFees = [];
    const feesByPostalCode: FeeByPostalCode[] = this.byPostalCodeFees || [];

    for (const postalCode of this.postalCodes) {
      postalCodeFees.push({
        postalCode,
        fee: this.$refs.preorderForm.form.deliveryFee,
      });
    }

    if (feesByPostalCode.length === 0) {
      return postalCodeFees;
    }

    for (const postalCodeFee of feesByPostalCode) {
      const fees = postalCodeFee.fees && postalCodeFee.fees.length > 0 ? postalCodeFee.fees : null;
      const maxFee: Fee = (fees &&
        fees.reduce((prevFee: Fee, curFee: Fee): Fee => (Number(curFee.fee) > Number(prevFee.fee) ? curFee : prevFee), {
          from: '',
          fee: '0',
        })) || { from: '', fee: this.$refs.preorderForm.form.deliveryFee };

      const index: number = postalCodeFees.findIndex(
        (postalCode: any) => postalCode.postalCode === postalCodeFee.postalCode,
      );

      if (index >= 0) {
        postalCodeFees[index] = {
          postalCode: postalCodeFee.postalCode,
          fee: maxFee.fee,
        };
      } else {
        postalCodeFees.push({
          postalCode: postalCodeFee.postalCode,
          fee: maxFee.fee,
        });
      }
    }

    return postalCodeFees;
  }

  public deliveryByRadiusChangeHandler(deliveryByRadius: boolean) {
    if (deliveryByRadius) {
      this.deliveryFeeType = 'radius';
    } else {
      this.deliveryFeeType = 'postalCode';
    }
  }

  public movChangeHandler(mov: any) {
    this.minValues.mov = (mov && mov.$numberDecimal) || mov;
  }

  public deliveryFeeChangeHandler(fee: any) {
    this.minValues.fee = (fee && fee.$numberDecimal) || fee;
  }

  public deliveryRadiusChangeHandler(radius: number) {
    this.minValues.deliveryRadius = radius;
  }

  public activeDeliveryTypeCallback(form: { deliveryByRadius?: boolean }) {
    return form.deliveryByRadius !== false;
  }

  public canChangeCoupon(venue: Venue) {
    if (venue?.couponsEnabled) {
      return this.$can(Permission.CATEGORY_COUPON_VISIBLE);
    }
    return this.$can(Permission.CATEGORY_COUPON_HIDE);
  }

  serializeFireTime() {
    const fireTime = this.$refs.fireTimeForm?.form ? { ...this.$refs.fireTimeForm?.form } : {};
    return Object.fromEntries(Object.entries(fireTime).filter(([_, value]) => value !== null && value !== ''));
  }

  public getData() {
    if (this.$refs.preorderOffsetForm) {
      return {
        ...this.$refs.preorderForm.form,
        ...this.$refs.preorderOffsetForm.form,
        deliveryFees: this.byRadiusFeesLegacy,
        deliveryFeesPostalCodes: this.byPostalCodeFeesLegacy,
        fireTime: {
          ...this.serializeFireTime(),
        },
        movAndFee: {
          mov: this.$refs.preorderForm.form.movDelivery,
          fee: this.$refs.preorderForm.form.deliveryFee,
          byRadius: this.byRadiusFees,
          byPostalCodes: this.byPostalCodeFees,
        },
      };
    }
    return {
      ...this.$refs.preorderForm.form,
      deliveryFees: this.byRadiusFeesLegacy,
      deliveryFeesPostalCodes: this.byPostalCodeFeesLegacy,
      fireTime: {
        ...this.serializeFireTime(),
      },
      movAndFee: {
        mov: this.$refs.preorderForm.form.movDelivery,
        fee: this.$refs.preorderForm.form.deliveryFee,
        byRadius: this.byRadiusFees,
        byPostalCodes: this.byPostalCodeFees,
      },
    };
  }
}
